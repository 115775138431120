import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'subjectInput',
    'bodyInput',
    'scheduleDatePicker',
    'scheduleHourTimePicker',
    'scheduleMinuteTimePicker',
    'recipientTable',
    'recipientCheckbox',
    'individualRecipientCheckbox'
  ];

  toggleScheduleFields(event) {
    let value = event.target.checked ? 'disable' : 'enable';

    this.scheduleDatePickerTarget.dispatchEvent(new CustomEvent(`promote:datepicker-${value}`));
    this.scheduleHourTimePickerTarget.dispatchEvent(new CustomEvent(`promote:number-picker-${value}`));
    this.scheduleMinuteTimePickerTarget.dispatchEvent(new CustomEvent(`promote:number-picker-${value}`));
  }

  //Ideally we would use outlets to render the content on the click
  changeTemplateContent(event) {
    const templateUrl = event.currentTarget.dataset["components-AdminInputsDropdownUrlParam"];

    fetch(templateUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => this._replaceContentTargets(data))
  }

  toggleRecipientTable(event) {
    if (event.target.checked) {
      this.openRecipientTable(event);
    } else {
      this.closeRecipientTable(event);
    }

  }

  openRecipientTable(event) {
    event.preventDefault();

    this.recipientTableTarget.classList.remove('hidden');
    this.recipientCheckboxTargets.forEach(checkbox => {
      checkbox.checked = false;
      checkbox.disabled = true;
    });

    ajax({
      url: this.membersUrl,
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }

  closeRecipientTable() {
    this.recipientTableTarget.classList.add('hidden');
    this.recipientTableTarget.innerHTML = '';
    this.recipientCheckboxTargets.forEach(checkbox => {
      checkbox.disabled = false;
    });
  }

  _replaceContentTargets(template) {
    this.subjectInputTarget.value = template.subject;
    this.bodyInputTarget.value = template.body;
  }

  get membersUrl() {
    return this.data.get('recipientTableUrl');
  }
}
