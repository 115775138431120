import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["formElement"];

  static values = {
    programId: Number,
    integrationId: Number,
    trackableId: Number,
    trackableType: String,
    progressTrackerId: Number,
    containerSelector: String
  }

  connect() {
    this.originalValue = this.formElementTarget.value;
  }

  get containerElement() {
    return document.querySelector(this.containerSelectorValue);
  }

  next(event) {
    const inlineEditors = Array.from(this.containerElement
      .querySelectorAll('[data-controller~="inline-edit-form"]'));

    const currentIndex = inlineEditors.indexOf(this.element);
    const nextElement  = inlineEditors[currentIndex + 1];

    if (nextElement) {
      event.preventDefault();

      const nextController = this.application
        .getControllerForElementAndIdentifier(nextElement, "inline-edit-form")

      this.submit(event);
      nextController.toggleEditor();
    } else {
      event.preventDefault();
      this.submit(event);
    }
  }

  submit() {
    event.preventDefault();

    const newValue = this.formElementTarget.value;

    if (newValue === '') {
      this._deleteProgressTracker();
    } else if (this.originalValue === '') {
      this._createProgressTracker(newValue);
    } else {
      this._updateProgressTracker(newValue);
    }
  }

  _createProgressTracker(newValue) {
    const body = new FormData();
    body.append('trackable_id', this.trackableIdValue);
    body.append('trackable_type', this.trackableTypeValue);
    body.append('trackable_gid', newValue);

    ajax({
      url: `/admin/programs/${this.programIdValue}/integrations/${this.integrationIdValue}/progress_trackers`,
      data: body,
      type: "POST"
    });
  }

  _updateProgressTracker(newValue) {
    const body = new FormData();
    body.append('trackable_gid', newValue);

    ajax({
      url: `/admin/programs/${this.programIdValue}/integrations/${this.integrationIdValue}/progress_trackers/${this.progressTrackerIdValue}`,
      data: body,
      type: "PUT"
    });
  }
  _deleteProgressTracker() {
    const body = new FormData();
    body.append('trackable_id', this.trackableIdValue);
    body.append('trackable_type', this.trackableTypeValue);

    ajax({
      url: `/admin/programs/${this.programIdValue}/integrations/${this.integrationIdValue}/progress_trackers/${this.progressTrackerIdValue}`,
      data: body,
      type: "DELETE"
    });
  }
}
